import { Button, Center, Stack, Text } from "@mantine/core";
import { ReactComponent as PaperPlaneTiltIcon } from "assets/images/icons/paper_plane_tilt.svg";
import MainLayout from "components/layouts/MainLayout";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

/**
 * 会員登録 登録済み会員メール送信完了ページ
 */
const SignupMemberSent: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE.SIGNIN}>
      <Stack spacing={32}>
        {/* サマリ */}
        <Text size="lg" weight="bold" ta="center">
          認証メールを送信しました
        </Text>
        {/* メール送信アイコン画像 */}
        <Center>
          <PaperPlaneTiltIcon width={72} height={72} />
        </Center>
        {/* 説明書き */}
        <Text color="#333333">
          ご登録のメールアドレスに認証メールを送信しました。
          <br />
          メールに記載されたURLをクリックし、アプリにログインしてください。
        </Text>
        <Stack spacing="md">
          {/* 説明書き */}
          <Text size="sm" color="#646464">
            メールが届かない方はメールアドレスを再入力してください。
          </Text>
          {/* 「メールアドレスを再入力する」ボタン */}
          <Button
            variant="outline"
            size="lg"
            fullWidth
            fz={16}
            onClick={() => navigate("/signup/member/new")}
          >
            メールアドレスを再入力する
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default SignupMemberSent;
