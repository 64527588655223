import { Button, Center, Stack, Text } from "@mantine/core";
import { useGetCurrentMember, usePostUsersConfirmAuth } from "api";
import { ReactComponent as MemberVerifiedIcon } from "assets/images/icons/member_verified.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { PAGE_TITLE } from "libs/constants";
import { customNotifications } from "libs/mantine/customNotifications";
import { useRef, type FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

/**
 * 会員登録 登録済み会員認証ページ
 */
const SignupMemberVerify: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate, isValidating } = useGetCurrentMember();
  const { trigger, data } = usePostUsersConfirmAuth();
  // トークン認証が実行済みかどうかを管理 (StrictMode による再実行を防ぐため)
  const isExecutedRef = useRef(false);

  useEffectOnce(() => {
    if (!isExecutedRef.current) {
      const token = searchParams.get("token");
      if (token) {
        // トークン認証実行
        void trigger(
          { token },
          {
            onError: ({ errors }) => {
              customNotifications.show({ message: errors, status: "error" });
              navigate("/signup/top");
            },
            throwOnError: false,
          }
        );
      } else {
        // パラメータにトークンが存在しない場合 /signup/top に戻ってやり直し
        navigate("/signup/top");
      }
    }
    // 実行済みにするクリーンアップ関数
    return () => {
      isExecutedRef.current = true;
    };
  });

  // トークン認証が成功するまではロード中を表示
  if (!data) return <Loading header={false} pageTitle={PAGE_TITLE.SIGNIN} />;

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE.SIGNIN}>
      <Stack spacing={48}>
        {/* 認証完了画像 */}
        <Center>
          <Stack spacing="md" align="center">
            <MemberVerifiedIcon width={125} height={121} />
            <Text size="lg" fw={500}>
              認証が完了しました
            </Text>
          </Stack>
        </Center>
        {/* 「ホームへ」ボタン */}
        <Button
          size="lg"
          fz={16}
          loading={isValidating}
          onClick={() => void mutate().then(() => navigate("/"))}
        >
          ホームへ
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default SignupMemberVerify;
