import { Anchor, Group, Image, Paper } from "@mantine/core";
import aupay_logo from "assets/images/aupay_logo.png";
import dbarai_logo from "assets/images/dbarai_logo.png";
import paypay_logo from "assets/images/paypay_logo.jpg";
import rakutenpay_logo from "assets/images/rakutenpay_logo.png";
import type { FC } from "react";

/**
 * 決済ツールリストカード
 */
const PaymentToolsCard: FC = () => {
  return (
    <Paper radius="md" p="md" sx={{ border: "1px solid #C5C5C5" }}>
      <Group position="apart" spacing={0}>
        {/* PayPay */}
        <Anchor href="paypay://">
          <Image src={paypay_logo} alt="PayPayロゴ" width={50} />
        </Anchor>
        {/* d払い */}
        <Anchor href="dpayment://">
          <Image src={dbarai_logo} alt="d払いロゴ" width={50} />
        </Anchor>
        {/* 楽天ペイ */}
        <Anchor href="rakutenpay://">
          <Image src={rakutenpay_logo} alt="楽天ペイロゴ" width={50} />
        </Anchor>
        {/* au PAY */}
        <Anchor href="auwallet://">
          <Image src={aupay_logo} alt="au PAYロゴ" width={50} />
        </Anchor>
      </Group>
    </Paper>
  );
};

export default PaymentToolsCard;
