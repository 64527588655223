// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack, Text } from "@mantine/core";
import { useGetCurrentPointsBalance, useGetUsersUrls } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { Navigate } from "react-router-dom";

/**
 * ゲスト会員用クーポントップページ
 *
 * ※ゲスト会員以外はアクセス不可
 */
const GuestCouponTop: FC = () => {
  const {
    data: currentPointsBalanceData,
    isValidating: isValidatingGetCurrentPointsBalance,
  } = useGetCurrentPointsBalance();
  const { data: usersUrlsData, isValidating: isValidatingGetUsersUrls } =
    useGetUsersUrls(
      !!currentPointsBalanceData && !currentPointsBalanceData.regular_member // ゲスト会員のみフェッチを実行
    );

  // 最新データ取得完了まではロード中を表示
  if (isValidatingGetCurrentPointsBalance || !currentPointsBalanceData)
    return <Loading pageTitle={PAGE_TITLE.COUPON} />;

  // ゲスト会員以外はトップへ飛ばす
  if (currentPointsBalanceData.regular_member) return <Navigate to="/" />;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE.COUPON} bodyColor="gray">
      <Stack spacing="md">
        <Text size="lg" weight="bold" ta="center">
          クーポンのご利用には会員登録が必要です。
        </Text>
        <Button
          size="lg"
          fullWidth
          fz={16}
          loading={!usersUrlsData || isValidatingGetUsersUrls} // データ未取得 or 最新データ取得中はクリックさせない
          onClick={() =>
            usersUrlsData &&
            liff.openWindow({ external: false, url: usersUrlsData.url })
          }
        >
          会員情報を登録する
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default GuestCouponTop;
