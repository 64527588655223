import {
  Center,
  Container,
  Image,
  Header as MantineHeader,
} from "@mantine/core";
import logo from "assets/images/logo.png";
import type { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
  return (
    <MantineHeader height={64} withBorder={false} bg="transparent">
      <Container
        size="sm" // MainLayout の横幅に合わせる
        h={48}
        p={0}
        sx={(theme) => ({
          backgroundColor: theme.white,
          boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1)",
        })}
      >
        {/* ロゴ */}
        <Center
          component={Link}
          to="/"
          mx="auto"
          w={64}
          h={64}
          p={8}
          sx={(theme) => ({
            backgroundColor: theme.white,
            borderRadius: theme.radius.xl,
          })}
        >
          {/* ロゴ画像 */}
          <Image src={logo} alt="Logo" fit="contain" />
        </Center>
      </Container>
    </MantineHeader>
  );
};

export default Header;
