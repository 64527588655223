// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useGetCurrentMember, useGetUsersUrls, usePostUsersGuests } from "api";
import MainLayout from "components/layouts/MainLayout";
import { useAtomValue } from "jotai";
import { PAGE_TITLE } from "libs/constants";
import { utmRequestParamsAtom } from "libs/jotai/atoms";
import { customNotifications } from "libs/mantine/customNotifications";
import { useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

/**
 * 会員登録トップページ
 */
const SignupTop: FC = () => {
  const navigate = useNavigate();
  // 会員規約同意チェックボックス値管理用
  const [checked, setChecked] = useState(false);
  const {
    mutate: mutateGetCurrentMember,
    isValidating: isValidatingGetCurrentMember,
  } = useGetCurrentMember();
  const {
    trigger: triggerPostUsersGuests,
    isMutating: isMutatingPostUsersGuests,
  } = usePostUsersGuests();
  const { data: usersUrlsData } = useGetUsersUrls();
  const utmRequestParams = useAtomValue(utmRequestParamsAtom);

  // 「会員登録せずにはじめる」ボタンクリックの処理
  const onClickGuestButton = () =>
    void triggerPostUsersGuests(
      { ...utmRequestParams },
      {
        onError: ({ errors }) =>
          customNotifications.show({ message: errors, status: "error" }),
        onSuccess: () =>
          void mutateGetCurrentMember().then(() => {
            customNotifications.show({
              message: "仮会員証を発行しました",
              status: "success",
            });
            navigate("/"); // ホームページへ
          }),
        throwOnError: false,
      }
    );

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE.SIGNUP}>
      <Stack spacing={32} mb={32}>
        {/* 説明書き */}
        <Text size="xl" weight="bold" ta="center">
          LINE会員証へようこそ
        </Text>
        {/* 会員規約同意チェックボックス */}
        <Center>
          <Checkbox
            fw="bold"
            styles={() => ({
              body: { alignItems: "center" },
            })}
            label={
              <>
                <Anchor
                  component="button"
                  color="#3078E5"
                  size="xl"
                  fw="bold"
                  onClick={() =>
                    liff.openWindow({
                      external: false,
                      url: "https://www.royal-hc.co.jp/cardmember/code",
                    })
                  }
                >
                  会員規約
                </Anchor>{" "}
                <Text size="xl" component="span">
                  を確認しました
                </Text>
              </>
            }
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Center>
        <Stack spacing="md">
          {/* 「LINE会員証をはじめる」ボタンと「会員登録せずにはじめる」ボタンの説明書き */}
          <Text size="xl" ta="center" c="#7C7C7C">
            2023年11月より前からご利用の方はこちら
          </Text>
          {/* 「LINE会員証をはじめる」ボタン（新規会員登録ボタン） */}
          <Button
            size="lg"
            fullWidth
            fz={24}
            disabled={!checked}
            loading={
              isMutatingPostUsersGuests ||
              isValidatingGetCurrentMember ||
              !usersUrlsData
            }
            onClick={() =>
              usersUrlsData &&
              liff.openWindow({ external: false, url: usersUrlsData.url })
            }
          >
            LINE会員証をはじめる
          </Button>
          {/* 「会員登録せずにはじめる」ボタン */}
          <Button
            variant="outline"
            size="lg"
            fullWidth
            fz={24}
            disabled={!checked}
            loading={isMutatingPostUsersGuests || isValidatingGetCurrentMember}
            onClick={onClickGuestButton}
          >
            会員登録せずにはじめる
          </Button>
          {/* 区切り線 */}
          <Center>
            <Divider
              color="#7C7C7C"
              labelPosition="center"
              w="80%"
              styles={(theme) => ({ label: { fontSize: theme.fontSizes.sm } })}
              label="または"
            />
          </Center>
          {/* 「ログイン」ボタン説明書き */}
          <Text size="xl" ta="center" c="#7C7C7C">
            2023年11月以降にご利用の方はこちら
          </Text>
          {/* 「ログイン」ボタン */}
          <Skeleton visible={!usersUrlsData}>
            <UnstyledButton
              display="block"
              mx="auto"
              fz="xl"
              fw="bold"
              c="brand"
              disabled={
                !checked ||
                isMutatingPostUsersGuests ||
                isValidatingGetCurrentMember
              }
              sx={{ "&:disabled": { opacity: 0.5 } }}
              onClick={() => navigate("/signup/member/new")}
            >
              ログイン
            </UnstyledButton>
          </Skeleton>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default SignupTop;
