export const BASE_URL =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:8000/api/v1/line";

export const PAGE_TITLE = {
  COUPON: "クーポン",
  DEFAULT: "ロイヤルホームセンター",
  HOME: "ホーム",
  MENU: "メニュー",
  SIGNIN: "ログイン",
  SIGNUP: "会員登録",
} as const;

export const ROYAL_HC_MYPAGE_BASE_URL =
  process.env.REACT_APP_ROYAL_HC_MYPAGE_BASE_URL ||
  "https://mypage.royal-hc.co.jp/stg";

// ゴールドランク達成の金額は10万円
export const GOLD_AMOUNTS_REQUIRED = 100_000;
// ゴールドのランク名
export const GOLD_RANK_NAME = "ゴールド";

/** 会員に表示するメニューリンク集 */
export const MEMBER_MENU_LINKS = {
  /** 招待コード */
  INVITATION_CODE: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/invitation_code.aspx`,
  /** ポイント履歴 */
  POINT_HISTORY: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/point.aspx`,
  /** 会員情報変更 */
  PROFILE_UPDATE: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/menu_customer.aspx`,
  /** 購入履歴 */
  PURCHASE_HISTORY: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/store_ec_history.aspx`,
};

/** 会員ステータスに関係なく表示するリンク集(その他) */
export const OTHER_MENU_LINKS = {
  /** アプリダウンロード（Android） */
  APP_DOWNLOAD_ANDROID:
    "https://play.google.com/store/apps/details?id=jp.co.royalhc",
  /** アプリダウンロード（iOS） */
  APP_DOWNLOAD_IOS: "https://apps.apple.com/jp/app/id1478702016",
  /** チラシ */
  FLYER: "https://www.royal-hc.co.jp/campaigns/now_flyer",
  /** お知らせ */
  NOTIFICATION: "https://www.royal-hc.co.jp/topics",
  /** リフォーム */
  REFORM: "https://www.royal-hc.co.jp/reform",
  /** ロイヤルホームセンター公式HP */
  ROYAL_HC_HP: "https://www.royal-hc.co.jp/",
  /** ロイヤルホームセンターLINE公式アカウント */
  ROYAL_HC_LINE_ACCOUNT:
    "https://liff.line.me/1645278921-kWRPP32q/?accountId=299npgye",
  /** ロイサポート */
  ROY_SUPPORT: "https://www.royal-hc.co.jp/roy-support",
  /** お役立ち情報 */
  USEFUL_INFORMATION: "https://diyclip.roymall.jp/",
};

/** 会員ステータスに関係なく表示するリンク集(ヘルプ) */
export const HELP_MENU_LINKS = {
  /** お問い合わせ */
  CONTACT: "https://www.royal-hc.co.jp/corporate/contact",
  /** プライバシーポリシー */
  PRIVACY_POLICY: "https://www.royal-hc.co.jp/corporate/info/privacy",
  /** よくある質問 */
  QA: "https://www.royal-hc.co.jp/cardmember/qa",
};
