import { Box, Skeleton } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import type { ComponentProps, FC } from "react";

type LoadingProps = Pick<
  ComponentProps<typeof MainLayout>,
  "header" | "pageTitle"
>;

/**
 * 画面全体のローディングコンポーネント
 */
const Loading: FC<LoadingProps> = ({ header = true, pageTitle }) => {
  return header ? (
    // ヘッダあり
    <MainLayout header footer={false} pageTitle={pageTitle}>
      {/* コンテンツ部分の Skeleton */}
      <Skeleton height={160} />
    </MainLayout>
  ) : (
    // ヘッダなし(ヘッダ部分を Skeleton)
    <MainLayout header={false} footer={false} pageTitle={pageTitle}>
      {/* ヘッダ部分の Skeleton */}
      <Box mt="-md" mx="-md">
        <Skeleton height={64} />
      </Box>
      {/* コンテンツ部分の Skeleton */}
      <Skeleton height={160} mt="md" />
    </MainLayout>
  );
};

export default Loading;
