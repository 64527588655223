import { Button, Stack } from "@mantine/core";
import { isEmail, matches, useForm } from "@mantine/form";
import { usePostUsersConfirmSend } from "api";
import MainLayout from "components/layouts/MainLayout";
import CustomTextInput from "components/modules/CustomTextInput";
import { useAtomValue } from "jotai";
import { PAGE_TITLE } from "libs/constants";
import { utmRequestParamsAtom } from "libs/jotai/atoms";
import { customNotifications } from "libs/mantine/customNotifications";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

type FormValues = {
  first_name_kana: string; // 名 (カナ)
  last_name_kana: string; // 姓 (カナ)
  mail_address: string; // メールアドレス
  postal_code: string; // 郵便番号 (ハイフンなし)
};

/**
 * 会員登録 登録済み会員による作成ページ
 */
const SignupMemberNew: FC = () => {
  const navigate = useNavigate();
  const { trigger, isMutating } = usePostUsersConfirmSend();
  const utmRequestParams = useAtomValue(utmRequestParamsAtom);
  const { onSubmit, getInputProps, isValid } = useForm<FormValues>({
    initialValues: {
      first_name_kana: "",
      last_name_kana: "",
      mail_address: "",
      postal_code: "",
    },
    validate: {
      // TODO: サーバー側のバリデーションルールに合わせてるが、厳しすぎないか9月の外部結合テストで要確認
      first_name_kana: matches(/^[ァ-ヶー]+$/, "名 (カナ)を入力してください"),
      last_name_kana: matches(/^[ァ-ヶー]+$/, "姓 (カナ)を入力してください"),
      mail_address: isEmail("正しいメールアドレスを入力してください"),
      postal_code: matches(/^\d{7}$/, "正しい郵便番号を入力してください"),
    },
    validateInputOnBlur: true,
  });

  // サブミット時の処理
  const handleSubmit = onSubmit(
    (values) =>
      void trigger(
        { ...utmRequestParams, ...values },
        {
          onError: ({ errors }) =>
            customNotifications.show({ message: errors, status: "error" }),
          onSuccess: () =>
            // 会員登録 登録済み会員メール送信完了ページへ
            navigate("/signup/member/sent"),
          throwOnError: false,
        }
      )
  );

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE.SIGNIN}>
      {/* フォーム */}
      <form onSubmit={handleSubmit}>
        <Stack spacing="md">
          {/* メールアドレス入力欄 */}
          <CustomTextInput
            label="メールアドレス"
            placeholder="sample@sample.com"
            {...getInputProps("mail_address")}
          />
          {/* 郵便番号入力欄 */}
          <CustomTextInput
            label="郵便番号"
            placeholder="1001000"
            {...getInputProps("postal_code")}
          />
          {/* 姓 (カナ)入力欄 */}
          <CustomTextInput
            label="姓 (カナ)"
            placeholder="タナカ"
            {...getInputProps("last_name_kana")}
          />
          {/* 名 (カナ)入力欄 */}
          <CustomTextInput
            label="名 (カナ)"
            placeholder="タロウ"
            {...getInputProps("first_name_kana")}
          />
          {/* 「認証メールを送信する」サブミットボタン */}
          <Button
            size="lg"
            fullWidth
            fz={16}
            type="submit"
            loading={isMutating}
            disabled={!isValid()}
          >
            認証メールを送信する
          </Button>
        </Stack>
      </form>
    </MainLayout>
  );
};

export default SignupMemberNew;
