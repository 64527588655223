// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import {
  Container,
  Group,
  Footer as MantineFooter,
  NavLink,
  rem,
  type NavLinkProps,
} from "@mantine/core";
import { useGetCurrentPointsBalance } from "api";
import { ReactComponent as CouponIcon } from "assets/images/icons/coupon.svg";
import { ReactComponent as ECIcon } from "assets/images/icons/ec.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import { ReactComponent as MenuIcon } from "assets/images/icons/menu.svg";
import { ReactComponent as StoresIcon } from "assets/images/icons/stores.svg";
import { ROYAL_HC_MYPAGE_BASE_URL } from "libs/constants";
import type { FC } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

type FooterItemProps = NavLinkProps & {
  onClick?: () => void;
};

const FooterItem: FC<FooterItemProps> = (props) => (
  <NavLink
    {...props}
    variant="subtle"
    c="#707070" // 通常時(非アクティブ時)の色
    color="brand" // アクティブ時の色
    styles={(theme) => ({
      body: { flex: "initial" },
      icon: { marginRight: 0 },
      label: {
        fontSize: rem(10),
        fontWeight: 500,
        whiteSpace: "nowrap",
      },
      root: {
        ":hover[data-active]": {
          backgroundColor: theme.colors.gray[0],
        },
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: rem(2),
      },
    })}
  />
);

const Footer: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: currentPointsBalanceData } = useGetCurrentPointsBalance();

  return (
    <MantineFooter height={68} withBorder={false} bg="transparent">
      <Container
        size="sm" // MainLayout の横幅に合わせる
        h={68} // 親幅に合わせる
        p={0}
        display="flex"
        sx={(theme) => ({
          backgroundColor: theme.white,
          boxShadow: "0px -2px 10px 0px #E2E2E2",
        })}
      >
        <Group grow w="100%" spacing={0} align="stretch">
          {/* ホーム */}
          <FooterItem
            label="ホーム"
            icon={<HomeIcon width={20} height={20} />}
            active={!!matchPath({ end: true, path: "/" }, pathname)}
            onClick={() => navigate("/")}
          />
          {/* 店舗検索 */}
          <FooterItem
            label="店舗検索"
            icon={<StoresIcon width={20} height={20} />}
            onClick={() =>
              liff.openWindow({
                external: false,
                url: "https://www.royal-hc.co.jp/shops",
              })
            }
          />
          {/* クーポン */}
          <FooterItem
            label="クーポン"
            icon={<CouponIcon width={20} height={20} />}
            active={!!matchPath({ end: false, path: "/coupon" }, pathname)}
            disabled={!currentPointsBalanceData}
            onClick={
              currentPointsBalanceData &&
              (() =>
                currentPointsBalanceData.regular_member
                  ? // 本会員はWebフロントへ
                    liff.openWindow({
                      external: false,
                      url: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/coupon.aspx`,
                    })
                  : navigate("/coupon")) // ゲストはゲスト会員用クーポントップページへ
            }
          />
          {/* 通販サイト */}
          <FooterItem
            label="通販サイト"
            icon={<ECIcon width={20} height={20} />}
            onClick={() =>
              liff.openWindow({
                external: false,
                url: "https://www3.roymall.jp/",
              })
            }
          />
          {/* メニュー */}
          <FooterItem
            label="メニュー"
            icon={<MenuIcon width={20} height={20} />}
            active={!!matchPath({ end: false, path: "/menu" }, pathname)}
            onClick={() => navigate("/menu")}
          />
        </Group>
      </Container>
    </MantineFooter>
  );
};

export default Footer;
