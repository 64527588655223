import { Flex, Group, Paper, RingProgress, Stack, Text } from "@mantine/core";
import { GOLD_AMOUNTS_REQUIRED, GOLD_RANK_NAME } from "libs/constants";
import type { FC } from "react";
import type { GetCurrentPointsBalanceResponseData } from "types/api";
import type { UserRankName } from "types/user";

type Props = {
  currentRankName: UserRankName;
  nextRankName: GetCurrentPointsBalanceResponseData["next_rank_name"];
  rankUpAmountsRequired: GetCurrentPointsBalanceResponseData["rank_up_amounts_required"];
  rankUpJudgementAmount: GetCurrentPointsBalanceResponseData["rank_up_judgement_amount"];
};

/**
 * 現在のランクカード
 */
const UserRankCard: FC<Props> = ({
  currentRankName,
  nextRankName,
  rankUpAmountsRequired,
  rankUpJudgementAmount,
}) => {
  return (
    <Paper radius="md" p="md" sx={{ border: "1px solid #C5C5C5" }}>
      <Stack spacing="md">
        <Group
          position="center"
          spacing="sm"
          bg="#F2F2F2"
          p="md"
          sx={(theme) => ({ borderRadius: theme.radius.md })}
        >
          <Flex columnGap={8} align="center">
            {/* 円グラフ */}
            <RingProgress
              size={50}
              rootColor="#005E3D4D"
              thickness={6}
              roundCaps
              // rankUpAmountsRequiredがnullの場合、ゴールドに達成していることになる
              // しかし、グラフを表示する必要があるため定数を利用して表示する
              sections={[
                {
                  color: "brand",
                  value:
                    (rankUpJudgementAmount /
                      (rankUpAmountsRequired || GOLD_AMOUNTS_REQUIRED)) *
                    100,
                },
              ]}
            />
            {/* 次の会員ランクへの金額 */}
            <Stack spacing={0}>
              <Text
                size="lg"
                weight="bold"
                data-testid="rank-up-judgement-amount"
              >
                {rankUpJudgementAmount.toLocaleString("ja-JP")} 円
              </Text>
              <Text size="sm" data-testid="rank-up-amounts-required">
                {`/ ${(
                  rankUpAmountsRequired || GOLD_AMOUNTS_REQUIRED
                ).toLocaleString("ja-JP")}円`}
              </Text>
            </Stack>
          </Flex>
          {/* 現在のランク */}
          <Stack spacing={4}>
            <Text size="sm" color="#7C7C7C">
              現在のランク
            </Text>
            <Text size="md" color="brand" weight="bold">
              {currentRankName}
            </Text>
          </Stack>
        </Group>
        {/* 次の会員ランクへの説明書き */}
        <Text size="xs" data-testid="next-rank-information">
          {/* 次ランクがゴールド以外の場合は、APIとの兼ね合いでランクアップ必要金額がアップデートされていくので常に表示する */}
          {/* 次ランクがゴールドの場合は、ランクアップ達成していない場合は表示、そうでない場合は非表示 */}
          {!!rankUpAmountsRequired &&
            (nextRankName !== "ゴールド" ||
              rankUpJudgementAmount < rankUpAmountsRequired) && (
              // 次の会員ランクの条件をまだ満たしていない時
              <>
                今月
                {(rankUpAmountsRequired - rankUpJudgementAmount).toLocaleString(
                  "ja-JP"
                )}
                円のお買い上げで
              </>
            )}
          次月{nextRankName || GOLD_RANK_NAME}会員です。
          <br />
          ※前日時点での金額になります。
        </Text>
      </Stack>
    </Paper>
  );
};

export default UserRankCard;
