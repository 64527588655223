import type { ErrorCode, ErrorTexts } from "types/error";

/**
 * エラーコードを付与してエラーページへ遷移させる
 * @param {ErrorCode} code
 * @param {number} [httpStatusCode]
 */
export const handleMoveToErrorPage = (
  code: ErrorCode,
  httpStatusCode?: number
) => {
  window.location.replace(
    `/error?error_code=${code}&http_status_code=${httpStatusCode ?? ""}`
  );
};

/**
 * エラーページに表示するタイトルと説明文を取得
 * @param {string} paramStr
 * @return {ErrorTexts}
 */
export const getErrorTexts = (paramStr: string): ErrorTexts => {
  const searchParams = new URLSearchParams(paramStr);
  const errorCode = searchParams.get("error_code");
  const httpStatusCode = searchParams.get("http_status_code");

  switch (errorCode) {
    case "server-error":
      return {
        description: `サーバー側でエラーが発生しました。\r\nアプリを再起動してください。${
          httpStatusCode ? `（status: ${httpStatusCode}）` : ""
        }`,
        title: "サーバーエラー",
      };
    case "session-expired":
      return {
        description:
          "セッションの有効期限が切れています。\r\nアプリを再起動してください。",
        title: "セッションの有効期限切れ",
      };
    case "init-liff-failure":
      return {
        description: "アプリの起動に失敗しました。",
        title: "起動失敗",
      };
    default:
      return {
        description: "エラーが発生しました。",
        title: "エラー",
      };
  }
};
