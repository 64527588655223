import { Button, Flex, Group, Paper, Stack, Text, rem } from "@mantine/core";
import { ReactComponent as ArrowClockwiseIcon } from "assets/images/icons/arrow_clockwise.svg";
import dayjs from "dayjs";
import type { FC } from "react";
import type { GetCurrentPointsBalanceResponseData } from "types/api";

type Props = {
  isUpdating: boolean;
  latestExpirationPoint: GetCurrentPointsBalanceResponseData["latest_expiration_point"];
  latestExpirationPointDate: GetCurrentPointsBalanceResponseData["latest_expiration_point_date"];
  onClickUpdate: () => void;
  pointBalance: GetCurrentPointsBalanceResponseData["point_balance"];
};

/**
 * 保有ポイント残高カード
 */
const UserPointStatsCard: FC<Props> = ({
  latestExpirationPoint,
  latestExpirationPointDate,
  pointBalance,
  isUpdating,
  onClickUpdate,
}) => {
  return (
    <Paper radius="md" p="md" sx={{ border: "1px solid #C5C5C5" }}>
      <Stack spacing={8}>
        {/* 更新日時 */}
        <Flex justify="space-between" align="center">
          {/* 現在日時 */}
          <Text size="xs">{dayjs().format("YYYY/MM/DD HH:mm")} 時点</Text>
          {/* 「更新」ボタン */}
          <Button
            size="xs"
            leftIcon={<ArrowClockwiseIcon width={16} height={16} />}
            variant="default"
            bg="#E4E4E4"
            styles={{
              label: { fontWeight: "bold" },
              leftIcon: { marginRight: rem(4) },
              root: { border: "1px solid #F2F2F2" },
            }}
            loading={isUpdating}
            onClick={onClickUpdate}
          >
            更新
          </Button>
        </Flex>
        {/* ポイント */}
        <Text
          size={32}
          align="center"
          weight="bold"
          data-testid="point-balance"
        >
          {pointBalance.toLocaleString("ja-JP")}{" "}
          <Text component="span" size="xl">
            P
          </Text>
        </Text>
        {/* 失効ポイント */}
        <Group spacing={8} position="center" c="#7C7C7C">
          <Text size="xs">うち失効ポイント</Text>
          <Text size="xs" weight="bold" data-testid="latest-expiration-point">
            {latestExpirationPoint
              ? latestExpirationPoint.toLocaleString("ja-JP")
              : 0}
            P
            {(latestExpirationPoint || 0) > 0 &&
              latestExpirationPointDate &&
              dayjs(latestExpirationPointDate).format("（YYYY/MM/DD）")}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
};

export default UserPointStatsCard;
