// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack, Title } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import type { FC } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

/**
 * エラーハンドリング用コンポーネント
 *
 * 参考: https://reactrouter.com/en/main/route/error-element
 */
const RootBoundary: FC = () => {
  const error = useRouteError();
  const errorText = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : "エラーが発生しました";

  return (
    <MainLayout header={false} footer={false} pageTitle={errorText}>
      <Stack
        // ボタンが最下部に固定されるように画面縦幅いっぱいの min-height を指定
        mih={`calc(100dvh - ${
          16 * 2 // MainLayout の上下 padding
        }px)`}
        spacing="md"
      >
        <Title order={2} align="center">
          {errorText}
        </Title>
        <Button
          size="lg"
          fullWidth
          fz={16}
          mt="auto"
          pos="sticky"
          bottom={16}
          onClick={() => liff.closeWindow()}
        >
          閉じる
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default RootBoundary;
