// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Paper, Stack, Text } from "@mantine/core";
import { useGetUsersUrls } from "api";
import type { FC } from "react";

/**
 * ゲストから本会員へ昇格する動線カード
 */
const GuestToRegularMemberCard: FC = () => {
  const { data: usersUrlsData, isValidating: isValidatingGetUsersUrls } =
    useGetUsersUrls();

  return (
    <Paper radius="md" p="md" sx={{ border: "1px solid #C5C5C5" }}>
      <Stack spacing="xs">
        <Text size="sm" align="center">
          本会員登録で機能開放！
        </Text>
        {/* 「会員情報を登録する」ボタン */}
        <Button
          size="lg"
          fullWidth
          fz={16}
          loading={!usersUrlsData || isValidatingGetUsersUrls} // データ未取得 or 最新データ取得中はクリックさせない
          onClick={() =>
            usersUrlsData &&
            liff.openWindow({ external: false, url: usersUrlsData.url })
          }
        >
          会員情報を登録する
        </Button>
      </Stack>
    </Paper>
  );
};

export default GuestToRegularMemberCard;
