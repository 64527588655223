// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { MantineProvider } from "@mantine/core";
import App from "App";
import { handleMoveToErrorPage } from "libs/handleError";
import { theme } from "libs/mantine";
import ErrorPage from "pages/Error";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "reportWebVitals";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);

if (window.location.pathname !== "/error") {
  liff
    .init({
      liffId: process.env.REACT_APP_LIFF_ID || "",
      withLoginOnExternalBrowser: true,
    })
    .then(() => {
      root.render(
        <StrictMode>
          <App />
        </StrictMode>
      );
    })
    .catch((e) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log("Error Initializing LIFF: ", e.message);
      localStorage.clear();

      handleMoveToErrorPage("init-liff-failure");
    });
} else {
  root.render(
    <StrictMode>
      <HelmetProvider>
        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
          <ErrorPage />
        </MantineProvider>
      </HelmetProvider>
    </StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
