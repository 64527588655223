// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack, Text, Title } from "@mantine/core";
import MainLayout from "components/layouts/MainLayout";
import { getErrorTexts } from "libs/handleError";
import type { FC } from "react";

/**
 * エラーページ
 */
const Error: FC = () => {
  const paramStr = window.location.search;
  const { title, description } = getErrorTexts(paramStr);

  return (
    <MainLayout header={false} footer={false} pageTitle={title}>
      <Stack
        // ボタンが最下部に固定されるように画面縦幅いっぱいの min-height を指定
        mih={`calc(100dvh - ${
          16 * 2 // MainLayout の上下 padding
        }px)`}
        spacing="md"
      >
        <Stack>
          <Title order={2} align="center">
            {title}
          </Title>
          <Text align="center">{description}</Text>
        </Stack>
        <Button
          size="lg"
          fullWidth
          fz={16}
          mt="auto"
          pos="sticky"
          bottom={16}
          onClick={() => liff.closeWindow()}
        >
          閉じる
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default Error;
