import { TextInput, rem, type TextInputProps } from "@mantine/core";
import type { FC } from "react";

/**
 * デザインをカスタマイズした Mantine の TextInput コンポーネント
 */
const CustomTextInput: FC<TextInputProps> = (props) => (
  <TextInput
    inputWrapperOrder={["label", "input", "description", "error"]}
    radius={6}
    styles={(theme) => ({
      description: {
        color: "#707070",
        fontSize: theme.fontSizes.xs,
        marginTop: rem(4),
      },
      input: {
        "&::placeholder": { color: "#BDBDBD" },
        borderColor: "#E0E0E0",
        paddingBlock: rem(8),
        paddingInline: rem(12),
      },
      label: {
        fontSize: theme.fontSizes.xs,
        fontWeight: "normal",
        marginBottom: rem(4),
      },
      wrapper: { marginBottom: 0 },
    })}
    {...props}
  />
);

export default CustomTextInput;
